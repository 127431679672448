.chardinjs-overlay {
	position: absolute;
	z-index: 9999990;
	background-color: #000;
	opacity: 0.8;
	width: 100%;
	left: 0;
	top: 0;
	-webkit-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.chardinjs-helper-layer {
	position: absolute;
	z-index: 9999999;
	color: white;
	-webkit-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.chardinjs-helper-layer.chardinjs-bottom {
	border-bottom: solid 1px;
	padding-bottom: 10px;
}

.chardinjs-helper-layer.chardinjs-top, .chardinjs-helper-layer.chardinjs-left, .chardinjs-helper-layer.chardinjs-right {
	border-top: solid 1px;
	padding-top: 10px;
}

.chardinjs-tooltip {
	position: absolute;
	-webkit-transition: opacity 0.1s ease-out;
	-o-transition: opacity 0.1s ease-out;
	transition: opacity 0.1s ease-out;
	max-width: 80%;
}

.chardinjs-tooltip.chardinjs-left {
    top: -36px !important;
    left: 20px !important;
}

.chardinjs-tooltip.chardinjs-right {
    top: -36px !important;
    right: 20px !important;
}

.chardinjs-tooltip.chardinjs-bottom {
	margin-bottom: -30px;
}

.chardinjs-tooltip.chardinjs-top, .chardinjs-tooltip.chardinjs-left, .chardinjs-tooltip.chardinjs-right {
	margin-top: -23px;
}

.chardinjs-tooltip.chardinjs-right:before, .chardinjs-tooltip.chardinjs-left:after, .chardinjs-tooltip.chardinjs-bottom:before, .chardinjs-tooltip.chardinjs-top:after {
	display: inline-block;
	font-size: 25px;
	background-color: white;
	height: 2px;
	position: absolute;
}

.chardinjs-tooltip.chardinjs-bottom:before, .chardinjs-tooltip.chardinjs-top:after, .chardinjs-tooltip.chardinjs-right:before, .chardinjs-tooltip.chardinjs-left:after {
	width: 0;
	height: 31px;
	left: calc(50% - 6px);
}

.chardinjs-tooltip.chardinjs-bottom:before {
	top: -30px;
	content: "↑";
}

.chardinjs-tooltip.chardinjs-top:after, .chardinjs-tooltip.chardinjs-left:after, .chardinjs-tooltip.chardinjs-right:before {
	bottom: -25px;
	content: "↓";
}

.chardinjs-show-element {
	z-index: 9999999;
	opacity: 0.5;
	background-color: #ffffff;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;  
}

.chardinjs-relative-position {
	position: relative;
}

.chardinjs-no-fixed {
	z-index: auto !important;
	position: absolute !important;
}

.chardinjs-tooltiptext {
	background: #ffffff;
	color: #3b5063;
	padding: 10px 15px;
}