/*********************/
/*******RADIO*******/
/******************/
input[type=radio]{
    position: absolute;
    right: 0;
    visibility: hidden;
}

input[type=radio]+.label-text:before {
    content: "";
    font-family: FontAwesome;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    font-size: 10px;
    height: 22px;
    width: 22px;
    float: left;
    cursor: pointer;
    border-radius: 100%;
    border: 1px solid #ccc;
    text-indent: 0;
    margin-right: 7px;
    margin-top: 3px;
    padding: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    top: -4px;
}

input[type=radio]:checked+.label-text:before {
    content: "\f111";
    color: #00677f;
    cursor: auto;
}

/********************/
/**** CHECKBOX *****/
/******************/
input[type=checkbox]{
    position: absolute;
    right: 0;
    visibility: hidden;
}

.checkStyle input[type=checkbox]+input[type=hidden]+span.checkFalse:before {
    content: "";
    font-family: FontAwesome;
    font-size: 20px;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    float: left;
    position: relative;
    top: 0px;
    left: 0px;
    width: 30px;
    height: 30px;
    display: flex;
    cursor: pointer;
    color: #a4a4a4;
    background: #fff;
    border: 1px solid #ccc;
    padding: 4px 0px 0px 4px;
    margin-right: 5px;
}

.checkStyle input[type=checkbox]:checked+input[type=hidden]+.checkFalse:before {
    content: "\f00c";
    color: #00677f;
    animation: effect 250ms ease-in;
}

/*********************/
/*******BOTONES*******/
/*********************/
.btn {
    display: inline-block;
    border: none;
    background-image: none;
    box-shadow: none;
    text-shadow: none;
    border-radius: 0;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 10px;
    font-family: 'Montserrat-Light',Verdana,Arial,Helvetica,sans-serif;
    font-weight: normal;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    letter-spacing: 0.05rem;
    color: #fff;
    cursor: pointer !important;
    pointer-events: auto !important;
}

.btn:hover{
    opacity: 0.6 !important;
}

body .btn-primary {
    color: #fff;
    background-color: #337ab7;
    padding: 8px 10px;}

    body .btn-primary:hover {
        opacity: 0.7;
        color: #fff;
        background-color: #337ab7;
    }

body .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    padding: 8px 10px;}

    body .btn-secondary:hover {
        opacity: 0.7;
        color: #fff;
        background-color: #6c757d;
    }

body .btn-warning {
    color: #000;
    background-color: #ffc107;
    padding: 8px 10px;}

    body .btn-warning:hover {
        opacity: 0.7;
        color: #000;
        background-color: #ffc107;
    }

.btn-content{
    display: flex;
    justify-content: flex-end;
}

.btn-content .btn {
    margin-left: 5px;
}

/** Botones fin de página **/
.btn-return {
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    color: #6c757d !important;
    background: #fff;
    border: 1px solid #6c757d;
    padding: 10px 12px;
}

 .btn-return:hover {
        background: #6c757d;
        color: #fff !important;
        border: 1px solid #6c757d;
    }

 .btn-next, body .btn-next:focus {
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    border: 1px solid #007bff;
    padding: 10px 12px;
    background: #fff;
    color: #007bff;
    float: right;
}

     .btn-next:hover {
        color: #fff;
        background: #007bff;
        border: 1px solid #007bff;
    }

.btn-delete {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 2px;
    background-color: #969696 !important;
    color: #fff !important;
}


.content-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
}

.btn-delete:hover {
    background: #6c757d;
    color: #fff !important;
    border: 1px solid #6c757d;
}

.content-buttons .btn {
  margin-right: 10px;
}