
/* Tipografías */


@font-face {
    font-family: 'Montserrat-Thin';
    src: url('../fonts/Montserrat-Thin.eot');
    src: url('../fonts/Montserrat-Thin.woff2') format('woff2'), url('../fonts/Montserrat-Thin.woff') format('woff'), url('../fonts/Montserrat-Thin.ttf') format('truetype'), url('../fonts/Montserrat-Thin.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-ExtraLight';
    src: url('../fonts/Montserrat-ExtraLight.eot');
    src: url('../fonts/Montserrat-ExtraLight.woff2') format('woff2'), url('../fonts/Montserrat-ExtraLight.woff') format('woff'), url('../fonts/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Light';
    src: url('../fonts/Montserrat-Light.eot');
    src: url('../fonts/Montserrat-Light.woff2') format('woff2'), url('../fonts/Montserrat-Light.woff') format('woff'), url('../fonts/Montserrat-Light.ttf') format('truetype'), url('../fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url('../fonts/Montserrat-Regular.eot');
    src: url('../fonts/Montserrat-Regular.woff2') format('woff2'), url('../fonts/Montserrat-Regular.woff') format('woff'), url('../fonts/Montserrat-Regular.ttf') format('truetype'), url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('../fonts/Montserrat-SemiBold.eot');
    src: url('../fonts/Montserrat-SemiBold.woff2') format('woff2'), url('../fonts/Montserrat-SemiBold.woff') format('woff'), url('../fonts/Montserrat-SemiBold.ttf') format('truetype'), url('../fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url('../fonts/Montserrat-Bold.eot');
    src: url('../fonts/Montserrat-Bold.woff2') format('woff2'), url('../fonts/Montserrat-Bold.woff') format('woff'), url('../fonts/Montserrat-Bold.ttf') format('truetype'), url('../fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}


.Montserrat-Thin {
    font-family: 'Montserrat-Thin';
}

.Montserrat-Light {
    font-family: 'Montserrat-ExtraLight';
}

.Montserrat-Light {
    font-family: 'Montserrat-Light';
}

.Montserrat-Regular {
    font-family: 'Montserrat-Regular';
}

.Montserrat-SemiBold {
    font-family: 'Montserrat-SemiBold';
}

.Montserrat-Bold {
    font-family: 'Montserrat-Bold';
}


