
/*-- Tipografías --*/
@import url(fonts.css);

/*-- base layout --*/
@import url(layout.css);

/*-- Estilos de estilos generales --*/
@import url(style.css);

/*-- Estilos de estilos UI --*/
@import url(elementosUI.css);

/*-- Estilos de sliders --*/
@import url(sliders.css);

/*-- animaciones --*/
@import url(wow_animate.css);

/*-- Estilos de estilos responsive --*/
@import url(media-queries.css);

/*-- Estilo Lienzo--*/
@import url(chardinjs.scss);