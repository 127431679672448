body{
    position: relative;
    font-family: Montserrat-Light,Verdana,Arial,Helvetica,sans-serif;
    font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

h1 {
    color: #fff !important;
    font-size: 2.3rem !important;
    margin-top: 5rem !important;
}

body h3 {
    margin-top: 8px;
    font-size: 17px;
    font-family: Montserrat-Bold,Verdana,Arial,Helvetica,sans-serif;
    text-transform: uppercase;
    color: #00677f;
}

* {
    margin: 0;
    padding: 0;
    border: 0;
}

main {
    padding: 0px 0 185px 0px;
    height: 100%;
    min-height: calc(100vh - 114px);
}

section {
    margin-top: 35px;
    min-height:1px;
}

a {
    color: #333;
    cursor: pointer;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
    color: #333;
}

img {
    border: none;
    height: auto;
}

p {
    line-height: 1.4em;
    font-size: 1em;
    margin: 0 0 15px;
}

li {
    list-style: none;
}

label{
    font-size: 15px;
}

.row label {
    font-size: 13px;
    margin-bottom: 5px;
    font-family: Montserrat-Bold,Verdana,Arial,Helvetica,sans-serif;
}

.block {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    margin-bottom: 15px;
    background-color: #fff;
    border-bottom: 3px solid #e5e7e9;
    border-top: 1px solid #e5e7e9;
    border-left: 1px solid #e5e7e9;
    border-right: 1px solid #e5e7e9;
    display: inline-block;
    width: 100%;
}
.block-title {
    color: #00677f;
    margin: 0 0 20px 15px;
    padding: 8px 1.4rem 4px 7px!important;
    text-transform: uppercase;
    border-left: 4px solid #ffb25b;
}

  /******************/
 /*** Títulos H ****/
/******************/
h3{
    margin-top: 8px;
    font-family: Montserrat-Bold,Verdana,Arial,Helvetica,sans-serif;
    text-transform: uppercase;
    color: #00677f;
}

.block h4.block-title{
    border-left: 4px solid #ffb25b;
    color: #00677f;
    margin: 0 0 30px;
    text-transform: uppercase;
    font-family: Montserrat-Bold,Verdana,Arial,Helvetica,sans-serif;
    font-weight: 700;
    font-size: 14px;
}

.title_main {
    background: #e5f0f2;
    padding: 15px;
}

.title_main h2{
    color: #00677F;
    margin: 0;
    font-size:14px;
    width: 100%;
    font-family: 'Montserrat-Bold',Verdana,Arial,Helvetica,sans-serif;
    text-transform: uppercase;
}

  /******************/
 /***** INPUTS *****/
/******************/
select.form-control, input.form-control, textarea.form-control, .multiselect-dropdown .dropdown-btn {
    border-radius: 2px !important;
    box-shadow: none;
    padding: 4px 9px 4px;
    height: 31px;
    font-size: 14px;
    border: 1px solid #d6d6d6 !important;
    margin-bottom: 10px;
}

select.form-control{
    appearance: revert !important;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    border: none !important;
    background: inherit !important;
    padding: 0 !important;
    color: inherit !important;
}

.multiselect-dropdown .multiselect-item-checkbox input[type=checkbox] + div:before {
    color: #00677f !important;
    border: 2px solid #00677f !important;

}

.multiselect-item-checkbox input[type=checkbox]:checked + div:before{
    color: #00677f !important;
    border: 2px solid #00677f !important;
}

.field-validation-valid.text-danger{
    display: block;
    position: relative;
    font-size: 12px;
    margin-bottom: 5px;
}

  /*********************************/
 /*** Formularios checks Radios ***/
/*********************************/
.form-check {
    padding-left: 0;
}

.formbuilder-radio-group{
    margin-bottom: 20px;
}

/*********************************************/
/********** Medidas personalizadas ***********/
/*********************************************/
.mtop0 {
    margin-top: 0px !important;
}

.mtop5 {
    margin-top: 5px !important;
}

.mtop10 {
    margin-top: 10px !important;
}

.mtop15 {
    margin-top: 15px !important;
}

.mtop20 {
    margin-top: 20px !important;
}

.mtop25 {
    margin-top: 25px !important;
}

.mtop30 {
    margin-top: 30px !important;
}

.mbottom0 {
    margin-bottom: 0px !important;
}

.mbottom5 {
    margin-bottom: 5px !important;
}

.mbottom10 {
    margin-bottom: 10px !important;
}

.mbottom15 {
    margin-bottom: 15px !important;
}

.mbottom20 {
    margin-bottom: 20px !important;
}

.mbottom25 {
    margin-bottom: 25px !important;
}

.mbottom30 {
    margin-bottom: 30px !important;
}

.mbottom200 {
    margin-bottom: 200px !important;
}

.mright5 {
    margin-right: 5px !important;
}

.mright10 {
    margin-right: 10px !important;
}

.mright15 {
    margin-right: 15px !important;
}

/*********************************************/
/******* Alineaciones personalizados *********/
/*********************************************/
.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.pull-right{
    float: right;
}

.pull-left{
    float: left;
}

/*******************************/
/********** Flex, displays ****/
/*****************************/
.jC-between{
   justify-content:space-between;
}
