/**  Login **/
@media (max-width: 1110px){
    .demo .rendered-form {
        position: relative;
        left: 0;
        right: 0;
        top: 65px;
        width: auto;
        margin: 0 auto;
    }
}

@media (max-width: 740px) {

    .container-fluid.demo {
        background-image: none;
        background-color: rgb(171, 171, 171);
        background-size: none;
    }

}

@media (max-width: 500px) {
    .demo .leftSide {
        height: 110px;
    }
    .demo .leftSide button {
        font-size: 10px;
    }

    .demo .leftSide img {
        margin-top: 39px;
    }
}

