  /***************/
 /*** HEADER ****/
/***************/
.header{
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
}

.header .navbar-nav li{
    margin-right: 15px;
}

.header .navbar-nav li:last-child{
    margin-right: 0;
}

.header nav{
    display: flex;
    align-items: center;
}

.header nav .titulo{
    margin-right: 15px;
}

.header nav .titulo h2{
    color: #00677f;
    font-size: 25px;
    margin: 0;
}

.header nav .navMenu{
    display: flex;
}

.header nav .navMenu a{
    font-size: 22px;
    color: #00677f;
    margin-right: 5px;
}

.header nav .navMenu a:last-of-type{
    margin-right: 0px;
}
  
/*****************/
/*** Inicio ***/
/*****************/
.inicio a {
    cursor: pointer;
    text-decoration: none;
}

.inicio a:hover {
    text-decoration: none;
    opacity: 0.6;
    color: #fff;
}

.inicio h2 {    
    color: #00677f;
}

.inicio .mat-stepper-horizontal {
    margin-top: 10px;
}

.inicio .mat-horizontal-stepper-header-container {
    margin-bottom: 10px;
}

.inicio .paso {
    padding: 50px 0;
    border: 1px solid black;
    text-align: center;
}

.inicio .cuadro {
    padding: 10px;
    border: 2px solid black;
}

.inicio .tabla {
    color: orange;
    background-color: lightgoldenrodyellow;
    padding: 10x;
    margin: 25px;
}

.inicio .tabla h3 {
    color: orange;
}

.inicio .cuadro ul li:before {
    content: '✓ ';
}

  /*****************/
 /* Páginas ERROR */
/*****************/
.errorPg {
    display: flex;
    align-items: center;
    color: #6e6e6e;
    flex-direction: column;
    height: 100%;
    background-color: #f2f2f2;
    padding-top: 60px;
    text-align: center;
}

    .errorPg section {
        max-width: 50%;
        padding: 30px;
        background-color: rgb(254, 249, 231,.4);
    }

    .errorPg section h3:first-child{
        font-size: 55px;
    }

    .errorPg section h3 span{
        margin-left: 20px;
    }

    .errorPg .callToAction  button{
        margin-top: 15px;
    }

    .errorPg .callToAction  p{
        font-size: 18px;
    }

  /*****************/
 /***** Modal *****/
/*****************/
.modal.fade-anim.in{
    overflow: scroll;
}

.outPopUp{
    background-color: #ffffff;
    text-align: left;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 4rem;
}

.outPopUp .headerDivModal {
    background-color: #00677f;
    font-size: small;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
  }

  .outPopUp .headerDivModal .close-modal-icon{
    font-size: 24px;
    color: #00677f;
    padding: 8px;
}

  .main-modal-body{
    padding: 0 20px 0 20px;
  }


  .outPopUp .title_main{
    margin: 15px 0 15px 0 !important;
}

.outPopUp .main-modal-body .modal-block-title{
    font-size: 12px !important;
  }

  .outPopUp .title_main + form .block:first-of-type{
    margin-top: 0px !important;
  }

  /*****************/
 /*** Mensajes ****/
/*****************/
.msnContainerSection {
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 15px;
}

.msnContainerSection .col-md-auto{
    margin-right: 0;
    padding-right: 0;
}

.msnContainerSection i{
    font-size: 21px;
    position: relative;
    top: 2px;
}

.msnContainerSection .bg-warning {
    padding: 10px;
    background-color: #fbe99c59 !important;
    color: #8a6d3b !important;
  }

.msnContainerSection .bg-info {
    padding: 10px;
    background-color: #d5ecee !important;
    color: #00677F;
}

.msnContainerSection .bg-success {
    padding: 10px;
    color: #5a883a;
    background-color: #eff6dc !important;
}

.msnContainerSection .bg-error {
    padding: 10px;
    color: #B43104;
    background-color: #FBF5EF !important;
}

/****************************/
/********* FOOTER **********/
/**************************/
.logow3c {
    padding: 0;
    max-width: 103px;
    width: 100%;
}

footer{
    position: absolute;
    bottom: 0;
    height: 150px;
    width: 100%;
    margin-top: 30px !important;
    background: #00677f;
}

footer p, footer a {
    font-size: 1.0 rem;
    color: #fff !important;
    margin: 0;
    padding: 0 0 0.5rem;
}

.ir-arriba {
	display:inline;
	padding:20px;
	background:#024959;
	color:#fff ;
	cursor:pointer;

	bottom:20px;
	right:20px;
}

.ir-arriba-div {
    text-align: right !important;
    margin-right: 20px;
    vertical-align: middle !important;
    display:flex;
    justify-content: end;
    align-items: end;

}

/****************************/
/***** PÁGINAS FOOTER ******/
/**************************/
.footerModal .closeIcon{
    position: absolute;
    width: auto;
    right: 8px;
    top: 0px;
    font-size: 25px;
    background: initial;
    color: #ffff;
}

.footerModal{
    position: relative;
    margin-bottom: 60px;
    padding-bottom: 20px;
}

.footerModal h2 {
    font-size: 1.8rem;
    text-transform: uppercase;
    padding: 15px 15px 15px 30px;
    color: #fff;
    background-color: #ff9c02;
}

.footerModal h3 {
    font-size: 18px;
    margin: 20px 20px 4px 0px;
    font-family: Montserrat,Verdana,Arial,Helvetica,sans-serif;
}

.footerModal p {
    line-height: 1.4em;
    font-size: 0.95em;
    margin: 0 0 8px;
}

.footerModal a {
    color: #ff9c02;
}

.footerModal a u {
    color: #ff9c02;
    -webkit-text-decoration-line: none;
    text-decoration-line: none;
    font-weight: 100;
}

.footerModal ul {
    list-style: none;
    display: block;
    margin: 6px -1px 15px;
    padding-left: 0px;

}

.footerModal li {
    list-style-type: disc;
    margin-left: 30px;
    padding: 4px 0;
    border: 0;
    display: list-item;
    width: auto;
    line-height: 1.4em;
    font-size: 0.95em;
}


/****************************/
/******** COOKIES ***********/
/****************************/
.cookies-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    font-family: 'TitilliumWeb-Regular',Verdana,Arial,Helvetica,sans-serif;
}

.cookies-layout {
    margin: 0 30px 0 0;
    font-size: 12px;
}

.cookies-title {
    font-size: 1.5em;
    margin: 0 0 15px 0;
    font-weight: bold;
}

.cookies-wrapper p{
    margin: 0 0 10px;
}

.cookies-buttons {
    min-width: 280px;
}

.cookies-buttons button {
    background: #4e4e4e;
    font-size: 13px;
    padding: 0.5em;
    margin: 10px;
    color:#fff;
    width: 100%;
    text-transform: uppercase;
}

.cookies-buttons button:hover{
    opacity: 0.8 !important;
    background: #4e4e4e;
    color:#fff;
}