.noDigitalSlide{
	display: none;
}

.topSlide {
	display: none;
}

.digitalSlide .n-pos {
    position: absolute;
    top: 5px;
    left: 15px;
    font-size: 6em;
    color: #ffffff;
    font-weight: 800;
    font-family: arial;
    letter-spacing: -10px;
}

.mosaic .n-pos {
    position: absolute;
    top: 5px;
    left: 15px;
    font-weight: 800;
    font-family: arial;
    font-size: 3em;
    letter-spacing: -6px;
}


  /******************************************/
 /************* Generales ******************/
/******************************************/	 

    /*slider estilos generales*/
	.slider {
        width: 100%;
        margin: 30px auto;
    }

    .slick-slide {
    	position: relative;
	    margin: 0px 20px;
	    background: transparent;
    }
	
    .slick-slide img {
		width: 100%;
		background: #fff;
	}

    .slick-slide a:hover {
      color: #d86018;
    }

    .slick-prev:before, .slick-next:before{
    	color: #fff;
	    font-family: 'Line Awesome Free';
	    font-size: 31px;
	    font-weight: 900;
    }
    
    .slick-prev:before {
      content: "\f104";
    }

    .slick-next:before {
      content: "\f105";
    }

    .slick-prev {
	    left: -35px;
	}

    
    .slick-active {
      opacity: 1;
    }

    .slick-current {
      opacity: 1;
    }

	 	 .slick-dots li.slick-active button:before, .slick-dots li button:before {
		    color: #1890d8;
		    font-size: 25px;
		}

	  /******************************************/
	 /************* Slider cabecera ***********/
	/******************************************/
	.one-time{
		position: relative;
	}

	.one-time .title{
		position: absolute;
		z-index: 9;
		font-size: 50px;
		color: #fff;
		bottom: -28px;
		right: 0;
		text-align: center;
		font-size: 36px;
		margin: 44px 0 45px;
		color: #fff;
		text-align: right;
		font-weight: 800;
		z-index: 9;
		background: rgba(0,0,0,.5);
		padding: 10px 26px;
		height: 65px;
	}

	.one-time .slick-slide{
		position: relative;
		margin: 0px;
	}

	  .one-time .slick-slide .box-animation{
	  	position: absolute;
	    top: 50%;
	    left: 5%;
	    z-index: 9999;
	    background: #1C1C1C;
	    color: #fff;
	    padding: 25px;
	    line-height: 2;
	    background: rgb(0, 0, 0, 0.4);
	    overflow: hidden;
		}

		.one-time .slick-slide img{
			width: 100%;
			height: 100%;
			min-height:	100%;
			display: block;
		}

	.one-time .slick-slide .box-animation h2{
		font-family: "Zilla Slab", "Times New Roman", Times, serif;
	    font-size: 39px;
	    font-weight: 700;
	    font-style: italic;
	}

    .one-time .slick-dots li.slick-active button:before, .one-time .slick-dots li button:before {
	    color: #fff;
	}

	.one-time .slide.slick-slide{
		max-height: 440px;
	}

	.slick-dotted.slick-slider {
		margin-bottom: 30px;
	}

	.one-time .slick-dotted.slick-slider {
		margin-bottom: 0px;
	}

	.one-time ul.slick-dots{
		position: absolute;
		list-style: none;
		display: block;
		text-align: left;
		padding: 0;
		width: auto;
		bottom: 15px;
		left: 15px;
	}

	  /************************************************/
	 /**** Slider Te puede interesar insideTextSl ****/
	/************************************************/
	.insideTextSl .slick-slide .sltxt{
		position: absolute;
	    bottom: -32px;
	    left: 0;
	    padding: 0;
	    max-height: 90%;
	    background: rgb(250, 250,250, .8);
	    -webkit-transition: bottom ease 0.8s;
	    -o-transition: bottom ease 0.8s;
	    transition: bottom ease 0.8s;
	}

	.insideTextSl .slick-slide .sltxt:hover{
		bottom: 0px;
		-webkit-transition: bottom  0.8s;
		-o-transition: bottom  0.8s;
		transition: bottom  0.8s;
	}


	.insideTextSl .slick-slide .sltxt p{
	    font-size: 15px;
	    text-align: left;
	    margin-bottom: 8px;
	    padding: 5px 7px;
	}
    

	.insideTextSl .slick-slide .btn-primary {
	    margin: 7px 0 0;
	}

	/********************************/
	/******* Slider clasico *********/
	/********************************/
	.sl-clasico a {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
		position: relative;
		background: aliceblue;
		min-height: 267px;
	}

	.sl-clasico .slick-prev{
		left: -8px;
    	cursor:pointer;
	}

	.sl-clasico .slick-next{
		right: 0;
	}

	.sl-clasico .title {
		position: relative;
	    width: 100%;
	    font-family: 'TitilliumWeb-light',Verdana,Arial,Helvetica,sans-serif !important;
	    font-size: 16px;
	}

	.sl-clasico .btn-primary{
		margin-top: 0px;
	} 

	.sl-clasico .slick-slide .sltxt {
		padding: 1em;
		background: aliceblue;
		-webkit-box-flex: 1;
		    -ms-flex-positive: 1;
		        flex-grow: 1;
	}

	.sl-clasico .slick-slide .sltxt .title{
		font-size: 18px;
		line-height: auto;
		color: #3b5063;
	}

	.sl-clasico .infoCurso{
		margin: 1em;
		margin-top: 0;
	}

	.slDifusiones .slick-track {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
	}
	
	.slDifusiones .slick-slide {
		height: inherit !important;
	}



	/******************************************/
	/*********** Slider focus ****************/
	/****************************************/
	.sl-focus{
		margin: 0 auto;
    	width: 95%;
	}

	.sl-focus .slick-slider {
		margin: 30px 0;
	}

	.sl-focus .slick-slide {
		-webkit-transform: scale(0.8);
		    -ms-transform: scale(0.8);
		        transform: scale(0.8);
		-webkit-transition: all 0.5s;
		-o-transition: all 0.5s;
		transition: all 0.5s;
	}

	.sl-focus .slick-current {
	    opacity: 1;
	    -webkit-transform: scale(1);
	        -ms-transform: scale(1);
	            transform: scale(1);
	    -webkit-transition: all 0.5s;
	    -o-transition: all 0.5s;
	    transition: all 0.5s;
	}

	.sl-focus.slick-initialized.slider {
	    margin-top: 5px !important;
	}

	.sl-focus.slick-initialized .slick-slide {
	    position: relative;
	    overflow: hidden;
	}

	.sl-focus .slick-slide .sltxt {
	    padding: 1em;
	    width: 100%;
	    background: aliceblue;
		max-height: 90%;
	}

	.sl-focus .slick-slide .sltxt p {
	    padding: 0;
	    font-size: 14px;
	    text-align: left;
	}

	.sl-focus .slick-slide .sltxt p:first-child {
	    font-size: 18px;
    	line-height: auto;
	    color: #3b5063;
	}

	.sl-focus .slick-slide .sltxt p:first-child:after {
	    content: " ";
	    border-bottom: 2px solid #3b5063;
	    display: block;
	    width: 20%;
	    margin: 5px 0 10px;
	}

	.sl-focus .slick-slide .infoCurso .clock fa-icon{
		margin-right: 3px;
		color: #ff9c02;
		position: relative;
		top: 1px;
	}

  /*************************************/
 /******* Slider Tier, Ranking ********/
/*************************************/	

.bg-sl-tier {
    background: -o-linear-gradient(top, #ffffff 372px, #eeeeee 0);
    background: linear-gradient(180deg, #ffffff 372px, #eeeeee 0);
}

.sl-tier {
	margin-bottom: 50px;
}

.sl-tier .slick-slide .title {
	font-size: 18px;
	padding: 10px 0;
}

.sl-tier .slick-slide a {
	position: relative;
}

.sl-tier .slick-slide a .n-pos {
    position: absolute;
    top: 175px;
    left: -18px;
    color: #1890d8;
    font-family: arial;
    font-size: 80px;
    font-weight: 800;
    letter-spacing: -10px;
    -webkit-text-stroke: 1px #1890d8;
    color: #fff;
    z-index: 9;
}

	.sl-tier img[data-object-fit="cover"] {
		-o-object-fit: cover;
		   object-fit: cover;
	}	


  /*****************************************/
 /************* Slider digitalizate *******/
/*****************************************/

.sl-digital .slick-prev {
	left: -16px;
}
.sl-digital  .slick-next {
	right: -3px;
}

.sl-digital div a{
	width: 100%;
    overflow: hidden;
    display: block;
	
}

.sl-digital div img{
	width: 100%;
    background-color: #fff;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
	padding: 10px;
	height: 90px;
	-o-object-fit: contain;
	   object-fit: contain;
}

.sl-digital a:hover img {
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
}

.sl-digital div img:last-child{
	margin-top: 15px;
}

  /*******************************************/
 /******* Slider digitalízate pyme **********/
/*******************************************/
.pyme 	ngx-slick-carousel .slick-prev{
    left: -15px;
	z-index: 99;
}

.pyme 	ngx-slick-carousel .slick-next{
	right: 0px;
	z-index: 99;
}

  /*******************************************/
 /*********** Slider primary ****************/
/*******************************************/
	.sl-primary{
		margin: 30px 0 30px 20px !important;
    	width: 95%;
	}

	.sl-primary .slick-current {
	    opacity: 1;
	    -webkit-transform: scale(1);
	        -ms-transform: scale(1);
	            transform: scale(1);
	    -webkit-transition: all 0.5s;
	    -o-transition: all 0.5s;
	    transition: all 0.5s;
	}

	.sl-primary.slick-initialized.slider {
	    margin-top: 5px !important;
	}

	.sl-primary.slick-initialized .slick-slide {
	    position: relative;
	    overflow: hidden;
	}

	.sl-primary .slick-slide .sltxt {
	    position: absolute;
	    bottom: 0;
	    left: 0;
	    padding: 30px 30px 20px;
	    width: 80%;
	    background: aliceblue;
	}

	.sl-primary .slick-slide .sltxt p {
	    padding: 0;
	    font-size: 14px;
	    text-align: left;
	}

	.sl-primary .slick-slide .sltxt p:first-child {
	    font-size: 21px;
    	line-height: 26px;
	    color: #3b5063;
	}